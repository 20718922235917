import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function About({ data }) {
  return (
    <>
      <Page title={"Om oss"}>
        <div className="py-12 bg-white">
          <div className="container max-w-screen-xl m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
              <div className="md:7/12 lg:w-6/12">
                <h1 className="text-2xl text-gray-900 font-bold md:text-4xl">
                  Om oss
                </h1>
                <p className="mt-6 text-gray-600">
                  Blomstertjänsten är en informationssida om blommor,
                  blomsteraffärer och blomsterförmedlingstjänster.
                </p>
                <p className="mt-4 text-gray-600">
                  Vårt mål är att ge användarna all den information de behöver
                  för att välja den blomstertjänst som bäst passar deras behov.
                </p>
                <p className="mt-4 text-gray-600">
                  Vi erbjuder snart också en katalog över blomsterbutiker och
                  jämförelser av blomsterleveranstjänster för att hjälpa
                  användarna att hitta de bästa erbjudandena.
                </p>
              </div>
              <div className="md:5/12 lg:w-5/12">
                <GatsbyImage
                  image={data.homeTop.childImageSharp.gatsbyImageData}
                  className={"rounded-2xl"}
                  alt="studenter"
                />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    homeTop: file(relativePath: { eq: "home2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
